import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import Timer from "./components/Countdown/Timer";
import PrivacyPolicy from "./components/Countdown/PrivacyPolicy";

import "./styles.css";

function AppLayout() {
    const location = useLocation();

    // Render only the PrivacyPolicy component if on the /privacy-policy route
    if (location.pathname === "/privacy-policy") {
        return <PrivacyPolicy />;
    }

    // Default layout with "Coming Soon" and Timer
    return (
        <div className="App">
            <div className="container">
                <h1>eKonobar</h1>
                <h2>Coming Soon</h2>
                <Timer />
                <footer>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </footer>
            </div>
        </div>
    );
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/*" element={<AppLayout />} />
            </Routes>
        </Router>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
